import { FormatResponse } from 'seed-core';

import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import type {
  ApplyLiveReleaseBuild,
  ApplyLiveReleaseBuildParams,
  BasicResponse,
  Build,
  CheckProductBuildReleaseStatusResponse,
  CheckProgressIntegrityResponse,
  CreateApplyLiveReleaseBuild,
  CreateManualSecurityCheckRequestParams,
  DeleteProductBuildResponse,
  DRMFilePagination,
  DRMFileParams,
  FetchProductBuildParams,
  FetchProductBuildResponse,
  FetchProductBuildUploadCompletedParams,
  GetApplyLiveReleaseBuildParams,
  GetDetailProductBuildId,
  GroupStorage,
  IntegrityFilePagination,
  IntegrityFileParams,
  IsAllRunOptionType,
  ProductBuildResponse,
  ProductKeyInfoParams,
  ProductKeyResponse,
  RequestStorageParams,
  UpdateBuildDRMMakerParams,
  UpdateBuildIntegrityParams,
  UpdateProductBuildParams,
  UpdateProductBuildResponse
} from '@/types/build/build.type';
import type { BuildReleaseEnableResponse } from '@/types/build/build-response';
import { camelToSnake, generateHeader, snakeToCamel } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

const callAppLauncherApi = async (teamId: string): Promise<ProductBuildResponse | undefined> => {
  const { data } = await useRequest<ProductBuildResponse>('studio/v1/games/builds/launch', {
    params: camelToSnake({ teamId })
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=View+list+builds
const fetchProductBuildUploadedApi = async (
  params: FetchProductBuildParams
): Promise<FetchProductBuildResponse | undefined> => {
  const { data: rawData } = await useRequest<any>('studio/v1/games/builds/search', {
    headers: generateHeader(
      HeaderTypes.XNation,
      HeaderTypes.XLang,
      HeaderTypes.XClientLang,
      HeaderTypes.CallerId
    ),
    params: camelToSnake(params),
    showCommonError: false,
    toCamelCase: false
  });

  let transformedData: any;

  if (rawData) {
    const deepCopy = JSON.parse(JSON.stringify(rawData));

    const savedErrorDetails: Record<number, any> = {};

    if (deepCopy.contents) {
      deepCopy.contents.forEach((build: any, index: number) => {
        if (build.vaccine && build.vaccine.error_detail) {
          savedErrorDetails[index] = build.vaccine.error_detail;
          delete build.vaccine.error_detail;
        }
      });
    }

    transformedData = snakeToCamel(deepCopy);

    if (transformedData?.contents) {
      Object.keys(savedErrorDetails).forEach((indexStr: string) => {
        const index = Number(indexStr);
        if (transformedData.contents[index]?.vaccine) {
          transformedData.contents[index].vaccine.errorDetail = savedErrorDetails[index];
        }
      });
    }
  }

  return transformedData;
};

// https://wiki.smilegate.net/display/SGVTN/API+get+builds+upload+complete+list
const fetchProductBuildUploadCompletedApi = async (
  params: FetchProductBuildUploadCompletedParams
): Promise<Build[] | undefined> => {
  const { data } = await useRequest<Build[]>('studio/v1/games/builds/upload-completed', {
    headers: generateHeader(
      HeaderTypes.XNation,
      HeaderTypes.XLang,
      HeaderTypes.XClientLang,
      HeaderTypes.CallerId
    ),
    params: camelToSnake(params)
  });

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Delete+Build
const deleteProductBuildApi = async (
  buildId: string,
  gameId: string
): Promise<DeleteProductBuildResponse | undefined> => {
  const { data } = await useRequest<DeleteProductBuildResponse | undefined>(
    `studio/v1/games/builds/${buildId}`,
    {
      method: ApiMethod.Delete,
      params: camelToSnake({ gameId }),
      headers: generateHeader(
        HeaderTypes.XNation,
        HeaderTypes.XLang,
        HeaderTypes.XClientLang,
        HeaderTypes.CallerId
      )
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Update+build+description
const updateProductBuildApi = async (
  params: UpdateProductBuildParams
): Promise<UpdateProductBuildResponse | undefined> => {
  const { data } = await useRequest<UpdateProductBuildResponse | undefined>(
    'studio/v1/builds/description',
    {
      method: ApiMethod.Put,
      data: camelToSnake(params),
      formatResponse: FormatResponse.Custom
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Find+build+detail
const getProductBuildIdApi = async (
  params: GetDetailProductBuildId
): Promise<Build | undefined> => {
  const { data: rawData } = await useRequest<any>('studio/v1/games/builds/detail', {
    method: ApiMethod.Get,
    params: camelToSnake(params),
    showCommonError: false,
    toCamelCase: false
  });

  if (rawData && rawData.vaccine && rawData.vaccine.error_detail) {
    const deepCopy = JSON.parse(JSON.stringify(rawData));
    const originalErrorDetail = { ...deepCopy.vaccine.error_detail };
    delete deepCopy.vaccine.error_detail;
    const transformedData = snakeToCamel(deepCopy) as Build;
    if (transformedData.vaccine) {
      transformedData.vaccine.errorDetail = originalErrorDetail;
    }
    return transformedData;
  }

  return snakeToCamel(rawData) as Build | undefined;
};

// https://wiki.smilegate.net/display/SGVTN/Get+List+build+file+meta+entry+Drm+maker
const getDRMFileListsApi = async (
  params: DRMFileParams
): Promise<DRMFilePagination | undefined> => {
  const { data } = await useRequest<DRMFilePagination | undefined>(
    'studio/v1/games/build-files/meta-entry/drm',
    {
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Update+build+DRM+maker+files
const updateBuildDRMMakerFilesApi = async (
  params: UpdateBuildDRMMakerParams
): Promise<BasicResponse | undefined> => {
  const { data } = await useRequest<BasicResponse | undefined>('studio/v1/builds/drm', {
    method: ApiMethod.Put,
    data: camelToSnake(params),
    formatResponse: FormatResponse.Custom
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=Get+List+build+file+meta+entry+with+integrity
const getIntegrityFileListsApi = async (
  params: IntegrityFileParams
): Promise<IntegrityFilePagination | undefined> => {
  const { data } = await useRequest<IntegrityFilePagination | undefined>(
    'studio/v1/games/build-files/meta-entry/integrity',
    {
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=459631017
const getGroupStorageApi = async (groupId: string): Promise<GroupStorage | undefined> => {
  const res = await useRequest<GroupStorage>(`studio/v1/group/${groupId}/storage`);

  return res?.data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+%7C+check+if+group+storage+request+existed
const checkGroupStorageRequestApi = async (groupId: string): Promise<boolean | undefined> => {
  const res = await useRequest<boolean>(
    `studio/v1/group/${groupId}/storage-volume/request/existed`
  );

  return res?.data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+%7C+check+if+group+storage+request+existed
const requestStorageApi = async (
  groupId: string,
  params: RequestStorageParams
): Promise<boolean | undefined> => {
  const res = await useRequest<boolean>(`studio/v1/group/${groupId}/storage-volume/request`, {
    method: ApiMethod.Post,
    params: camelToSnake(params)
  });

  return res?.data;
};

// https://wiki.smilegate.net/display/SGVTN/Update+integrity+files
const updateBuildIntegrityFilesApi = async (
  params: UpdateBuildIntegrityParams
): Promise<BasicResponse | undefined> => {
  const { data } = await useRequest<BasicResponse | undefined>('studio/v1/builds/integrity', {
    method: ApiMethod.Put,
    data: camelToSnake(params),
    formatResponse: FormatResponse.Custom
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461504283
const getProductKeyInfoAPI = async (
  params: ProductKeyInfoParams
): Promise<ProductKeyResponse | undefined> => {
  const { data } = await useRequest<ProductKeyResponse | undefined>('studio/v1/products/game', {
    method: ApiMethod.Get,
    params: camelToSnake(params)
  });

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Create+manual+security+check+request
const createManualSecurityCheckRequestApi = async (
  params: CreateManualSecurityCheckRequestParams
): Promise<BasicResponse | undefined> => {
  const { errorDetail, ...restParams } = params;

  const transformedParams = camelToSnake(restParams);

  const finalParams = {
    ...transformedParams,
    error_detail: errorDetail
  };

  const { data } = await useRequest<BasicResponse | undefined>('studio/v1/builds/security-manual', {
    method: ApiMethod.Post,
    data: finalParams,
    formatResponse: FormatResponse.Custom
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=API+get+apply+live+release+build+information
const getApplyLiveReleaseBuildAPI = async (
  params: GetApplyLiveReleaseBuildParams
): Promise<ApplyLiveReleaseBuild | undefined> => {
  const { data } = await useRequest<ApplyLiveReleaseBuild | undefined>(
    'studio/v1/builds/release/info',
    {
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=API+release+game+build+for+apply+live
const createApplyLiveReleaseBuildApi = async (
  params: ApplyLiveReleaseBuildParams
): Promise<CreateApplyLiveReleaseBuild | undefined> => {
  const { data } = await useRequest<CreateApplyLiveReleaseBuild | undefined>(
    'studio/v1/builds/release',
    {
      method: ApiMethod.Post,
      data: camelToSnake(params),
      formatResponse: FormatResponse.Custom
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/Find+Build+Release+Enable
export const findBuildReleaseEnableApi = async (productNo: number | string): Promise<Build[]> => {
  const url = `${apiUrl}/games/builds/release/enable`;
  const { data } = await useRequest<BuildReleaseEnableResponse | undefined>(url, {
    method: ApiMethod.Get,
    params: camelToSnake({ productNo })
  });

  return data?.contents || [];
};

// https://wiki.smilegate.net/display/SGVTN/API+check+whether+all+run+execution+option+is+apply+on+a+product
export const fetchIsAllRunOptionApplyApi = async (
  productNo: number | string
): Promise<boolean | undefined> => {
  const { data } = await useRequest<{ applied: boolean } | undefined>(
    `${apiUrl}/builds/run-options/product/check`,
    {
      params: camelToSnake({ productNo })
    }
  );

  return data?.applied;
};

// https://wiki.smilegate.net/display/SGVTN/API+check+run+options+type+exist+on+a+product
export const fetchIsAllRunOptionTypeApi = async (
  productNo: number | string
): Promise<IsAllRunOptionType | undefined> => {
  const { data } = await useRequest<IsAllRunOptionType | undefined>(
    `${apiUrl}/builds/run-options/check`,
    {
      params: camelToSnake({ productNo })
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/API+check+product+build+release+status
const checkProductBuildReleaseStatusApi = async (
  productNo: string
): Promise<CheckProductBuildReleaseStatusResponse | undefined> => {
  const url = `${apiUrl}/builds/product-release/check`;
  const { data } = await useRequest<CheckProductBuildReleaseStatusResponse | undefined>(url, {
    method: ApiMethod.Get,
    params: camelToSnake({ productNo })
  });

  return data;
};

// https://wiki.smilegate.net/display/SGVTN/API+Get+latest+live+build+of+game
const getLatestLiveBuildApi = async (gameId: string): Promise<Build | undefined> => {
  const url = `${apiUrl}/builds/live/latest`;

  const { data } = await useRequest<Build | undefined>(url, {
    method: ApiMethod.Get,
    params: camelToSnake({ gameId }),
    showCommonError: false
  });

  return data;
};

const checkProgressIntegrityApi = async (
  buildId: string
): Promise<CheckProgressIntegrityResponse | undefined> => {
  const url = `${apiUrl}/games/build-files/meta-entry/integrity/progress`;

  const { data } = await useRequest<CheckProgressIntegrityResponse | undefined>(url, {
    method: ApiMethod.Get,
    params: camelToSnake({ buildId }),
    showCommonError: false,
    showLoading: false
  });

  return data;
};

export {
  callAppLauncherApi,
  checkGroupStorageRequestApi,
  checkProductBuildReleaseStatusApi,
  checkProgressIntegrityApi,
  createApplyLiveReleaseBuildApi,
  createManualSecurityCheckRequestApi,
  deleteProductBuildApi,
  fetchProductBuildUploadCompletedApi,
  fetchProductBuildUploadedApi,
  getApplyLiveReleaseBuildAPI,
  getDRMFileListsApi,
  getGroupStorageApi,
  getIntegrityFileListsApi,
  getLatestLiveBuildApi,
  getProductBuildIdApi,
  getProductKeyInfoAPI,
  requestStorageApi,
  updateBuildDRMMakerFilesApi,
  updateBuildIntegrityFilesApi,
  updateProductBuildApi
};
